import { Box, Paper, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import {
  Dashboard,
  SupervisorAccount,
  Key,
  Chat,
  People,
  Groups,
  Handshake,
  Recommend,
  LabelImportant,
  NewspaperOutlined,
  PriorityHigh
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { includes, map } from 'lodash';
import { FC, ReactNode } from 'react';
import { ModuleNames } from '../../../types/permissions.enum';
import { usePermissions } from '../../../hooks/usePermissions';

interface SideNavLinkProps {
  name: string;
  link: ModuleNames;
  canView: boolean | undefined;
  icon: ReactNode;
}

const SideNav: FC<{ isSidebarOpen: boolean }> = ({ isSidebarOpen }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const canViewPermissions = usePermissions(ModuleNames.PERMISSIONS).canView;
  const canViewTestimonials = usePermissions(ModuleNames.TESTIMONIALS).canView;
  const canViewMentors = usePermissions(ModuleNames.MENTORS).canView;
  const canViewDashboard = usePermissions(ModuleNames.DASHBOARD).canView;
  const canViewUsers = usePermissions(ModuleNames.USERS).canView;
  const canViewTicker = usePermissions(ModuleNames.TICKER).canView;
  const canViewTeam = usePermissions(ModuleNames.TEAM).canView;
  const canViewPartners = usePermissions(ModuleNames.PARTNERS).canView;
  const canViewSupport = usePermissions(ModuleNames.SUPPORT).canView;
  const canViewMentorLeads = usePermissions(ModuleNames.MENTOR_LEADS).canView;
  const canViewSalesLeads = usePermissions(ModuleNames.SALES_LEADS).canView;
  const canViewPrograms = usePermissions(ModuleNames.PROGRAMS).canView;
  const canViewCourses = usePermissions(ModuleNames.COURSES).canView;
  const canViewFaqs = usePermissions(ModuleNames.FAQS).canView;
  const canViewNewsLetters = usePermissions(ModuleNames.NEWS_LETTERS).canView;
  const canViewPlans = usePermissions(ModuleNames.PLANS).canView;

  const sidenavLinks: SideNavLinkProps[] = [
    {
      name: 'Dashboard',
      link: ModuleNames.DASHBOARD,
      canView: canViewDashboard,
      icon: <Dashboard />,
    },
    {
      name: 'Mentor Leads',
      link: ModuleNames.MENTOR_LEADS,
      canView: canViewMentorLeads,
      icon: <LabelImportant />,
    },
    {
      name: 'Sales Leads',
      link: ModuleNames.SALES_LEADS,
      canView: canViewSalesLeads,
      icon: <LabelImportant />,
    },
    {
      name: 'Programs',
      link: ModuleNames.PROGRAMS,
      canView: canViewPrograms,
      icon: <LabelImportant />,
    },
    {
      name: 'Courses',
      link: ModuleNames.COURSES,
      canView: canViewCourses,
      icon: <LabelImportant />,
    },
    {
      name: 'Employers',
      link: ModuleNames.EMPLOYERS,
      canView: true,
      icon: <NewspaperOutlined />,
    },
    {
      name: 'Students',
      link: ModuleNames.STUDENTS,
      canView: true,
      icon: <NewspaperOutlined />,
    },
    {
      name: 'Mentors',
      link: ModuleNames.MENTORS,
      canView: canViewMentors,
      icon: <SupervisorAccount />,
    },
    {
      name: 'Permissions',
      link: ModuleNames.PERMISSIONS,
      canView: canViewPermissions,
      icon: <Key />,
    },
    {
      name: 'Testimonials',
      link: ModuleNames.TESTIMONIALS,
      canView: canViewTestimonials,
      icon: <Chat />,
    },
    {
      name: 'Users',
      link: ModuleNames.USERS,
      canView: canViewUsers,
      icon: <People />,
    },
    {
      name: 'Team',
      link: ModuleNames.TEAM,
      canView: canViewTeam,
      icon: <Groups />,
    },
    {
      name: 'Backed By',
      link: ModuleNames.BACKED_BY,
      canView: canViewSupport,
      icon: <Recommend />,
    },
    {
      name: 'Partners',
      link: ModuleNames.PARTNERS,
      canView: canViewPartners,
      icon: <Handshake />,
    },
    {
      name: 'Tickers',
      link: ModuleNames.TICKER,
      canView: canViewTicker,
      icon: <LabelImportant />,
    },
    {
      name: 'Faqs',
      link: ModuleNames.FAQS,
      canView: canViewFaqs,
      icon: <LabelImportant />,
    },
    {
      name: 'Subscribers',
      link: ModuleNames.NEWS_LETTERS,
      // canView: canViewNewsLetters,
      canView: true,
      icon: <NewspaperOutlined />,
    },
    {
      name: 'Plans',
      link: ModuleNames.PLANS,
      canView: true,
      icon: <NewspaperOutlined />,
    },
    {
      name: 'Jobs',
      link: ModuleNames.JOBS,
      canView: true,
      icon: <NewspaperOutlined />,
    },
    {
      name: 'Complaints',
      link: ModuleNames.COMPLAINTS,
      canView: true,
      icon: <PriorityHigh />,
    }
  ];

  return (
    <Box height={'calc(100vh - 70px)'} sx={{background: 'linear-gradient(180deg, #000021 0%, #171735 50%, #3dc1e0 100%)'}}>
      <Paper sx={{ borderRadius: 0, height: '100%', background: 'transparent' }}>
        <Box height={'100%'} py={4} overflow={'auto'}>
          <List component="nav" aria-label="main mailbox folders" color="primary">
            {map(
              sidenavLinks,
              (navLink) =>
                navLink.canView && (
                  <ListItemButton
                    selected={includes(pathname, navLink.link)}
                    onClick={() => navigate(`/${navLink.link}`)}
                    key={navLink.link}
                    sx={{color: 'white'}}
                  >
                    <ListItemIcon sx={{ minWidth: '40px', color: 'white' }}>{navLink.icon}</ListItemIcon>
                    {isSidebarOpen && <ListItemText primary={navLink.name} sx={{ '& span': { fontWeight: 500 } }} />}
                  </ListItemButton>
                )
            )}
          </List>
          {/* <Divider />
                    <List component="nav" aria-label="main mailbox folders">
                        <ListItemButton
                            selected={true}
                            // onClick={(event) => handleListItemClick(event, 0)}
                        >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Inbox" />
                        </ListItemButton>
                        <ListItemButton
                            // selected={selectedIndex === 1}
                            // onClick={(event) => handleListItemClick(event, 1)}
                        >
                            <ListItemIcon>
                                <DraftsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Drafts" />
                        </ListItemButton>
                    </List> */}
        </Box>
      </Paper>
    </Box>
  );
};

export default SideNav;
